<template>
  <v-container class="pa-0">
    <base-material-card
      color="accent"
      icon="mdi-bullseye-arrow"
      inline
      class="px-5 py-3 mt-7"
      style="width: 100%"
    >
      <template v-slot:after-heading>
        <v-row align="center">
          <v-col
            v-if="showViewAsCustomer"
            class=""
            cols="6"
            sm="6"
            md="4"
            lg="4"
          >
            <ViewAsCustomer />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="6"
            md="3"
            lg="2"
          >
            <FilterUserNowwId />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="4"
            md="3"
            lg="2"
          >
            <StatusFilter
              :items="taStatusItems"
              prop-name="taStatus"
              store-action="setTAStatus"
              label="Target Status"
            />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="4"
            md="3"
            lg="2"
          >
            <DaysFromNow label="Order Dates" />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="4"
            md="3"
            lg="2"
          >
            <OrderTypeFilter />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="4"
            md="3"
            lg="2"
          >
            <FileTypeFilter />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="3"
            md="2"
          >
            <!-- <UploadTA
              v-if="$store.getters['fileTypeFilter']() === 32"
              :tenant-crm-id="$store.getters['simulatedCustomerCrmId']()"
              @upload-success="uploadTASuccess()"
            /> -->
            <v-tooltip
              v-if="showNewTA && disableNewTA"
              top
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  readonly
                  dark
                  color="grey"
                  :loading="isLoading()"
                  v-on="on"
                  @click="clickDisabledNewTA"
                >
                  New Target Audience
                </v-btn>
              </template>
              <span>Please select Dashboard filters for a new Target Audience</span>
            </v-tooltip>
            <router-link
              v-else-if="showNewTA"
              :to="nowwNewTALink"
              style="text-decoration: none;"
            >
              <v-btn
                color="success"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                New Target Audience
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
      <TATable
        :items="items"
        :loading="dashboardLoading"
        @reload="updateDashboard"
      />
    </base-material-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { taStatus } from '@/shared/models/noww'
import claims from '@/shared/models/auth/claims'
import { nowwService } from '@/shared/services'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'TADashboard',

  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer'),
    FilterUserNowwId: () =>
      import('@/views/pages/components/user-state/FilterUserNowwId'),
    StatusFilter: () =>
      import('@/views/pages/components/user-state/StatusFilter'),
    DaysFromNow: () =>
      import('@/views/pages/components/user-state/DaysFromNow'),
    OrderTypeFilter: () =>
      import('@/views/pages/components/user-state/OrderTypeFilter'),
    FileTypeFilter: () =>
      import('@/views/pages/components/user-state/FileTypeFilter'),
    TATable: () =>
      import('@/views/pages/dashboards/TATable')
    // UploadTA: () =>
    //   import('@/views/pages/components/mailing/UploadTA')
  },

  data: function () {
    return {
      items: [],
      dashboardLoading: false,
      unsubscribe: null
    }
  },

  created () {
    this.$store.commit('mailing/reset', null)
    this.updateDashboard()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER,
        mutationTypes.SET_FILTER_USER_NOWW_ID,
        mutationTypes.SET_DAYS_FROM_NOW,
        mutationTypes.SET_TA_STATUS,
        mutationTypes.SET_ORDER_TYPE_FILTER,
        mutationTypes.SET_FILE_TYPE_FILTER
      ]
      if (listeners.includes(mutation.type)) {
        this.updateDashboard()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    ...mapGetters(['isLoading']),
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    showNewTA () {
      return this.hasClaimKV(claims.ITA_DSC)
    },
    taStatusItems () {
      return taStatus
    },
    disableNewTA () {
      return (
        this.isLoading() ||
        this.$store.getters['fileTypeFilter']() === 0 ||
        (this.$store.getters['auth/isMMSRole']() &&
          this.$store.getters['isViewAllCustomers']()) ||
        (this.$store.getters['isSimulatedAsCustomer']() &&
          this.$store.getters['filterUserNowwId']() === 0))
    },
    nowwNewTALink () {
      let route = {
        name: 'NowwRedirect',
        params: {
          nowwRoute: `count-start-submit-ins.aspx`
        }
      }
      if (this.$store.getters['fileTypeFilter']() !== 0) {
        route.query = {
          FileTypeId: this.$store.getters['fileTypeFilter'](),
          ViewAsCustomer: this.$store.getters['simulatedCustomerNowwId'](),
          FilterUserNowwId: this.$store.getters['filterUserNowwId'](),
          TenantNowwId: this.$store.getters['auth/getTenantNowwId'](),
          UserNowwId: this.$store.getters['auth/getUserNowwId'](),
          CSAgent: this.$store.getters['auth/getFullName']()
        }
      } else {
        route.params = {
          nowwRoute: `count-start.aspx`
        }
      }
      return route
    }
  },

  methods: {
    updateDashboard () {
      if (this.$store.getters['isViewAllCustomers']()) {
        this.items = []
        return
      }
      this.dashboardLoading = true
      nowwService
        .getDashboard({
          userId: this.$store.getters['auth/getUserId'](),
          viewAsCustomer: this.$store.getters['simulatedCustomerCrmId'](),
          days: this.$store.getters['daysFromNow'](),
          filterUser: this.$store.getters['filterUserNowwId'](),
          status: this.$store.getters['taStatus'](),
          nowwOrderType: this.$store.getters['orderTypeFilter'](),
          fileTypeId: this.$store.getters['fileTypeFilter']()
        })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.items = []
          } else {
            this.items = resp.map(x => (
              {
                ...x,
                orderDate: moment(this.$_global_parseAsUtc(x.orderDate)),
                _expandedIcon: 'down'
              }
            ))
          }
        }).finally(() => {
          this.dashboardLoading = false
        })
    },
    clickDisabledNewTA () {
      if (process.env.VUE_APP_IS_DEBUG === 'true') {
        console.log(`${this.isLoading()} ||
        ${this.$store.getters['fileTypeFilter']() === 0} ||
        (${this.$store.getters['auth/isMMSRole']()} &&
          (${this.$store.getters['isViewAllCustomers']()} ||
            ${this.$store.getters['filterUserNowwId']() === null ||
              this.$store.getters['filterUserNowwId']() === 0}))`)
      }
    },
    uploadTASuccess () {
      this.updateDashboard()
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
