var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mt-7",
          staticStyle: { width: "100%" },
          attrs: { color: "accent", icon: "mdi-bullseye-arrow", inline: "" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function() {
                return [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _vm.showViewAsCustomer
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "6", md: "4", lg: "4" } },
                            [_c("ViewAsCustomer")],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", sm: "6", md: "3", lg: "2" } },
                        [_c("FilterUserNowwId")],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", sm: "4", md: "3", lg: "2" } },
                        [
                          _c("StatusFilter", {
                            attrs: {
                              items: _vm.taStatusItems,
                              "prop-name": "taStatus",
                              "store-action": "setTAStatus",
                              label: "Target Status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", sm: "4", md: "3", lg: "2" } },
                        [
                          _c("DaysFromNow", { attrs: { label: "Order Dates" } })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", sm: "4", md: "3", lg: "2" } },
                        [_c("OrderTypeFilter")],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", sm: "4", md: "3", lg: "2" } },
                        [_c("FileTypeFilter")],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", sm: "3", md: "2" } },
                        [
                          _vm.showNewTA && _vm.disableNewTA
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    readonly: "",
                                                    dark: "",
                                                    color: "grey",
                                                    loading: _vm.isLoading()
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.clickDisabledNewTA
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                New Target Audience\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3660698803
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Please select Dashboard filters for a new Target Audience"
                                    )
                                  ])
                                ]
                              )
                            : _vm.showNewTA
                            ? _c(
                                "router-link",
                                {
                                  staticStyle: { "text-decoration": "none" },
                                  attrs: { to: _vm.nowwNewTALink }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { color: "success" } },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v(
                                          "\n                mdi-plus\n              "
                                        )
                                      ]),
                                      _vm._v(
                                        "\n              New Target Audience\n            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("TATable", {
            attrs: { items: _vm.items, loading: _vm.dashboardLoading },
            on: { reload: _vm.updateDashboard }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }